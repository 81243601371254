<app-menubar [showBackButton]="false" header="Settings.title"></app-menubar>

<ion-content [fullscreen]="true" class="content ion-padding">
    <ion-header collapse="condense">
        <ion-toolbar>
            <ion-title size="large">{{'Settings.title' | translate}}</ion-title>
        </ion-toolbar>
    </ion-header>

    <button (click)="installApp()" *ngIf="!appState.pwaInstalled" class="topButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.installApp"></fa-icon>
        {{'Settings.installApp' | translate}}
    </button>
    <button (click)="navigateToLicensePage()" class="topButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.license"></fa-icon>
        {{'LicensePage.title' | translate}}
    </button>
    <button (click)="reportError()" class="topButton" color="primary" mat-stroked-button>
        <fa-icon [icon]="appIcons.reportError"></fa-icon>
        {{'Settings.reportError' | translate}}
    </button>

    <mat-form-field>
        <mat-label>{{'Settings.language'|translate}}</mat-label>
        <mat-select [(value)]="language">
            <mat-option value="en">EN - {{'Settings.english' | translate}}</mat-option>
            <mat-option value="de">DE - {{'Settings.german' | translate}}</mat-option>
            <mat-option value="tr">TR - {{'Settings.turkish' | translate}}</mat-option>
            <mat-option value="zh">ZH - {{'Settings.chinese' | translate}}</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>{{'Settings.lengthUnit'|translate}}</mat-label>
        <mat-select [(value)]="thicknessUnit">
            <mat-option value="µm">µm</mat-option>
            <mat-option value="mm">mm</mat-option>
            <mat-option value="mil">mil</mat-option>
            <mat-option value="inch">inch</mat-option>
            <mat-option value="pc">pc</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-card class="personalization-card">
        <p class="consoleLoggerTitle">{{'Settings.personalization' | translate}}</p>
        <p class="checkbox-description">{{"Settings.personalizationDescription" | translate}}</p>
        <mat-form-field>
            <mat-label>{{'Settings.companyName' | translate}}</mat-label>
            <input [(ngModel)]="companyName" matInput />
        </mat-form-field>
        <mat-form-field>
            <mat-label>{{'Settings.companyAddress' | translate}}</mat-label>
            <textarea [(ngModel)]="companyAddress"
                      cdkAutosizeMinRows="4"
                      cdkTextareaAutosize
                      matInput
            ></textarea>
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'Settings.email' | translate}}</mat-label>
            <input [(ngModel)]="email" matInput />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'Settings.website' | translate}}</mat-label>
            <input [(ngModel)]="website" matInput />
        </mat-form-field>

        <mat-form-field>
            <mat-label>{{'Settings.phone' | translate}}</mat-label>
            <input [(ngModel)]="phone" matInput />
        </mat-form-field>

        <input #logoSelect accept="image/png, image/jpeg" class="notRendered" type="file" />

        <img *ngIf="logo" [src]="logo" alt="company logo" class="company-logo">
        <p *ngIf="!logo">{{'Settings.noLogo' | translate}}</p>

        <div class="extraActions">
            <button (click)="uploadLogo()" color="primary" mat-flat-button>
                <fa-icon [icon]="appIcons.addPhoto"></fa-icon>
                {{'Settings.uploadLogo' | translate}}
            </button>
            <button (click)="removeLogo()" *ngIf="logo" color="warn" mat-flat-button>
                <fa-icon [icon]="appIcons.trash"></fa-icon>
                {{'Settings.removeLogo' | translate}}
            </button>
        </div>
    </mat-card>

    <mat-card *ngIf="false" class="checkbox">
        <mat-checkbox (change)="enableLicenseCheck($event.checked)"
                      [checked]="licenseCheckEnabled">
            <p class="checkbox-title">{{"Settings.licenseCheckEnabled" | translate}}: {{(licenseCheckEnabled ? "Settings.enabled" : "Settings.disabled") | translate}}</p>
            <p class="checkbox-description">{{"Settings.licenseCheckDescription" | translate}}</p>
        </mat-checkbox>
    </mat-card>

    <mat-accordion>
        <mat-expansion-panel (closed)="logPanelOpenState = false" (opened)="logPanelOpenState = true">
            <mat-expansion-panel-header>
                <mat-panel-title class="consoleLoggerTitle">
                    <fa-icon [icon]="appIcons.debug"></fa-icon>
                    <p>{{'Settings.consoleLogger' | translate}}</p>
                </mat-panel-title>
            </mat-expansion-panel-header>
            <mat-list>
                <button (click)="refresh()" *ngIf="logPanelOpenState" class="refreshLogger" mat-stroked-button matListItemLine>
                    <fa-icon [icon]="appIcons.genericRefresh"></fa-icon>
                    {{'Settings.refresh' | translate}}
                </button>
                <mat-list-item *ngFor="let item of logs">
                        <span matListItemTitle>
                            <fa-icon *ngIf="item[1] === 'Warning'" [icon]="appIcons.logsWarning" class="warning"></fa-icon>
                            <fa-icon *ngIf="item[1] === 'Debug'" [icon]="appIcons.logsDebug" class="debug"></fa-icon>
                            <fa-icon *ngIf="item[1] === 'Error'" [icon]="appIcons.logsError" class="error"></fa-icon>
                            <fa-icon *ngIf="item[1] === 'Info'" [icon]="appIcons.logsInfo"></fa-icon>
                            <strong>{{item[1]}}</strong> {{item[0]}}
                        </span>
                    <span matListItemLine>
                         <span *ngFor="let message of item[2]">{{message}}</span>
                    </span>
                </mat-list-item>
            </mat-list>
        </mat-expansion-panel>
    </mat-accordion>

    <div class="imprint">
        <h2>{{'Settings.imprint' | translate}}</h2>
        <p>
            Rhopoint Instruments Ltd.,<br />
            Rhopoint House,<br />
            Enviro 21 Park,<br />
            Queensway Avenue South,<br />
            St. Leonards-on-Sea,<br />
            East Sussex,<br />
            TN38 9AG, UK<br />
            <br />
            E-Mail: service&#64;rhopointinstruments.com<br />
            Phone: +44 1424 739 622<br />
            <br />
            <span (click)="openPrivacyPolicy()" class="link">{{'Form.privacyPolicyLinkText' | translate}}</span>
        </p>
    </div>

    <div class="versionInfo">Version: 1.3.6</div>

    <div class="extraActions">
        <button (click)="deleteAllData()" color="warn" mat-stroked-button>
            <fa-icon [icon]="appIcons.trash"></fa-icon>
            {{'Settings.deleteAllData' | translate}}
        </button>
    </div>
</ion-content>
